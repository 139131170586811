import http from "../utils/http"
const baseUrl = '/app/study'

/**
 * 获取考试列表
 * @param params{classId:num, status:num[0为考试、1已考试或已结束]}
 * method:get
 * path:'/app/study/test/paper'
*/

export const getTestList = params => http.get(`${baseUrl}/test/paper`, {params})

/**
 * 获取考试信息
 * @param testPaperId:num 
 * method:get
 * path:'/app/study/test/paper/{testPaperId}'
*/

export const getTestInfo = testPaperId => http.get(`${baseUrl}/test/paper/${testPaperId}`)

/**
 * 开始考试
 * @param testPaperId
 * method:post
 * path:'/app/study/test/paper/{testPaperId}/start'
*/

export const startTest = testPaperId => http.post(`${baseUrl}/test/paper/${testPaperId}/start`)

/**
 * 获取试卷列表
 * @param params{classId:num, status:num[0为考试、1已考试或已结束]}
 * method:get
 * path:'/app/study/test/paper/list'
*/

export const getPcTestList = params => http.get(`${baseUrl}/test/paper/list`, {params})