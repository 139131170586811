import http from "../utils/http"
const baseUrl = '/app/study'

/**
 * 交卷
 * @param answerContent:Object
 * @param memberTestPaperId:num 
 * method:put
 * path:'/app/study/test/paper/test/{memberTestPaperId}'
*/

export const handInpapers = (answerContent,memberTestPaperId) => http.put(`${baseUrl}/test/paper/test/${memberTestPaperId}`, answerContent)


/**
 * 考后查看试卷
 * @param testPaperId
 * method:get
 * path:'/app/study/test/paper/{testPaperId}/review'
*/

export const reviewPaper = testPaperId => http.get(`${baseUrl}/test/paper/${testPaperId}/review`)