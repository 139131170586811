import Moment from 'moment'
import Message from 'element-ui'

/**
 * 格式化时间
 */
export const formatDate = function (date, fmt = "YYYY/MM/DD HH:mm:ss") {
  return Moment(date).format(fmt);
};

/**
 * 秒转化为mm:ss格式
 */
export const formatVideoTime = function (t) {
  if (!t) return;
  if (t < 60) return "00:" + ((i = t) < 10 ? "0" + i : i);
  if (t < 3600) return "" + ((a = parseInt(t / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
  if (3600 <= t) {
    var a, i, e = parseInt(t / 3600);
    return (e < 10 ? "0" + e : e) + ":" + ((a = parseInt(t % 3600 / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
  }
}

/**
 * 不足两位补零
 */
export const formatNumber = function (n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}


// 剩余时间
export const surplusTime = function (startTime, endTime) {
  if (new Date(startTime) == 'Invalid Date') {
    Message.error('开始时间不正确')
    return '';
  }
  if (new Date(endTime) == 'Invalid Date') {
    Message.error('结束时间不正确')
    return '';
  }
  let time = (new Date(endTime) - new Date(startTime)) / 1000;
  if (time <= 0) {
    return 0
  } else {
    if (time < 60) return `${time}秒`
    if (time < 3600) return ((a = parseInt(time / 60)) < 10 ? "0" + a : a) + "分钟";
    if (3600 <= time) {
      var a, e = parseInt(time / 3600);
      return (e < 10 ? "0" + e : e) + "小时" + ((a = parseInt(time % 3600 / 60)) < 10 ? "0" + a : a) + "分钟";
    }
  }
}

// 返回考试时间
export const testTime = function (nowTime, endTime) {
  if (new Date(nowTime) == 'Invalid Date') {
    Message.error('时间格式不正确')
    return '';
  }
  if (new Date(endTime) == 'Invalid Date') {
    Message.error('结束时间格式不正确')
    return '';
  }
  let time = (new Date(endTime) - new Date(nowTime))/1000;
  if (time <= 0) {
    return 0
  } else {
    return parseInt(time)
  }
}