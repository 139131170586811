export default {
    data() {
        return {

        }
    },
    methods: {
        // 题型标题
        questionTitle(name) {
            if (name == "radio") {
                return "单选题";
            } else if (name == "checkbox") {
                return "多选题";
            } else if (name == "boolean") {
                return "判断题";
            } else if (name == "supply") {
                return "填空题";
            } else {
                return "简答题";
            }
        },
        // 题型序号
        questionIndex(index) {
            if (index == 1) {
                return "一";
            } else if (index == 2) {
                return "二";
            } else if (index == 3) {
                return "三";
            } else if (index == 4) {
                return "四";
            } else if (index == 5) {
                return "五";
            }
        },
        // 统计各题型总分
        totleScore(list) {
            let score = 0;
            if (list.length !== 0) {
                list.forEach((e) => {
                    score += e.score;
                });
            }
            return score;
        },
    }
}