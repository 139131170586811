<template>
  <div class="test_paper_content_sty">
    <div class="test_paper_content_title_sty">
      <div class="test_paper_info_sty">
        <div class="back_icon_sty">
          <i class="iconfont" @click="goBack">&#xe625;</i
          ><span>{{ testInfo.title }}</span>
        </div>
        <div>
          <i class="iconfont icon_col_sty">&#xe633;</i
          ><span>数量:{{ totalNum }}</span
          ><i class="iconfont icon_col_sty">&#xe634;</i
          ><span>满分:{{ testInfo.totalScore }}</span>
        </div>
      </div>
      <div class="count_down_content_sty">
        <i class="iconfont">&#xe622;</i> 剩余
        <div>{{ minutes }}</div>
        <span>:</span>
        <div>{{ seconds }}</div>
      </div>
    </div>
    <div
      class="test_paper_main_sty"
      v-for="(i, index) in radioQuestions"
      :key="index"
    >
      <div class="test_paper_main_title_sty">
        {{ questionIndex(index + 1) }}、{{ questionTitle(Object.keys(i)[0])
        }}<span
          >（共{{ i[Object.keys(i)[0]] ? i[Object.keys(i)].length : 0 }}题
          {{ totleScore(i[Object.keys(i)]) }}分）</span
        >
      </div>
      <div class="single_content_sty">
        <single
          v-for="(i, index) in i.radio"
          :key="i.questionId"
          :question="i"
          :idx="index + 1"
          :historyAnswer="cacheAnswerList[i.questionId]"
          class="single_item_sty"
          @chooseAnswer="chooseAnswer"
        />
        <multiple
          v-for="(i, index) in i.checkbox"
          :key="i.questionId"
          :question="i"
          :idx="index + 1"
          :historyAnswer="cacheAnswerList[i.questionId]"
          class="single_item_sty"
          @chooseAnswer="chooseAnswer"
        />
        <judge
          v-for="(i, index) in i.boolean"
          :key="i.questionId"
          :question="i"
          :idx="index + 1"
          :historyAnswer="cacheAnswerList[i.questionId]"
          class="single_item_sty"
          @chooseAnswer="storageAnswer"
        />
        <supply
          v-for="(i, index) in i.supply"
          :key="i.questionId"
          :question="i"
          :idx="index + 1"
          :historyAnswer="cacheAnswerList[i.questionId]"
          class="single_item_sty"
          @updataAnswer="updataAnswer"
        />
        <answer
          v-for="(i, index) in i.answer"
          :key="i.questionId"
          :question="i"
          :idx="index + 1"
          :historyAnswer="cacheAnswerList[i.questionId]"
          class="single_item_sty"
          @updataBlankAnswer="updataBlankAnswer"
        />
      </div>
    </div>
    <div class="btn_content_sty">
      <div class="btn_sty" @click="handInpapers">交卷</div>
    </div>
    <div class="imbibition_content_sty" v-show="isImbibition">
      <div class="left_content_syt">
        <div>
          <i class="iconfont" @click="goBack">&#xe625;</i
          ><span>{{ testInfo.title }}</span>
        </div>
        <div>
          <i class="iconfont icon_col_sty">&#xe633;</i
          ><span>数量:{{ totalNum }}</span
          ><i class="iconfont icon_col_sty">&#xe634;</i
          ><span>满分:{{ testInfo.totalScore }}</span>
        </div>
      </div>
      <div class="count_down_content_sty">
        <i class="iconfont">&#xe622;</i> 剩余
        <div>{{ minutes }}</div>
        <span>:</span>
        <div>{{ seconds }}</div>
      </div>
    </div>
  </div>
</template>


<script>
import { startTest, getTestInfo } from "@/api/test";
import { testTime } from "@/utils/index";
import single from "../questionTemplates/single.vue";
import multiple from "../questionTemplates/multiple.vue";
import judge from "../questionTemplates/judge.vue";
import supply from "../questionTemplates/supply.vue";
import answer from "../questionTemplates/answer.vue";
import { handInpapers } from "@/api/examination";
import { surplusTime } from "@/utils/index";
import mixinsData from "@/mixins/data";
import { mapActions } from "vuex";
export default {
  components: {
    single,
    multiple,
    judge,
    supply,
    answer,
  },
  mixins: [mixinsData],
  data() {
    return {
      testId: this.$route.query.testId || null,
      testContent: {},
      testInfo: {},
      minutes: 0,
      seconds: 0,
      testTime,
      answerContent: {},
      surplusTime,
      isImbibition: false,
      timer: null,
      cacheAnswerList: [],
    };
  },
  created() {
    this.getTestInfo();
    this.startTest();
  },
  watch: {
    second: {
      handler(newVal) {
        this.num(newVal);
      },
    },
    minute: {
      handler(newVal) {
        this.num(newVal);
      },
    },
    cacheAnswerList: {
      deep: true,
      handler: function () {
        let paperId = this.testContent.memberTestPaperId;
        let paperAnswerList =
          JSON.parse(localStorage.getItem("paperAnswerList")) || [];
        let obj = {};
        obj[paperId] = this.cacheAnswerList;
        if (paperAnswerList.length == 0) {
          paperAnswerList.push(obj);
        } else {
          let key = -1;
          paperAnswerList.forEach((e, index) => {
            let id = Object.keys(e)[0];
            if (id == paperId) {
              key = index;
            }
          });
          if (key > -1) {
            paperAnswerList.splice(key, 1);
          }
          paperAnswerList.push(obj);
        }
        localStorage.setItem(
          "paperAnswerList",
          JSON.stringify(paperAnswerList)
        );
      },
    },
    answerContent: {
      deep: true,
      handler: function () {
        console.log(this.answerContent, "要提交的答案");
      },
    },
  },
  computed: {
    //   题目总数
    totalNum: function () {
      return (
        this.testInfo.radioCount +
        this.testInfo.checkboxCount +
        this.testInfo.booleanCount +
        this.testInfo.supplyCount +
        this.testInfo.answerCount
      );
    },
    second: function () {
      return this.num(this.seconds);
    },
    minute: function () {
      return this.num(this.minutes);
    },
    // 格式化题目
    radioQuestions: function () {
      let list = [];
      if (this.testContent.questionList) {
        this.testContent.questionList.forEach((e) => {
          let type = e.type;
          let obj = {};
          if (list.length == 0) {
            obj[type] = [e];
            list.push(obj);
          } else {
            let isHas = false;
            list.forEach((item) => {
              let currenType = Object.keys(item)[0];
              if (currenType == type) {
                item[currenType].push(e);
                isHas = true;
              }
            });
            if (!isHas) {
              let newObj = {};
              newObj[type] = [e];
              list.push(newObj);
            }
          }
        });
      }
      return list;
    },
    currentTime() {
      return this.$store.state.currentTime;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取答题记录
    getAnswerHistory() {
      let paperId = this.testContent.memberTestPaperId;
      let paperAnswerList = JSON.parse(localStorage.getItem("paperAnswerList")) || [];
      paperAnswerList.forEach((e) => {
        let id = Object.keys(e)[0];
        let value = Object.values(e)[0];
        if (id == paperId) {
          this.cacheAnswerList = value;
        }
      });
    },
    //   获取试卷信息
    getTestInfo() {
      getTestInfo(this.testId).then((json) => {
        const { code, data, msg } = json;
        if (code === 200) {
          this.testInfo = data;
        } else {
          this.$message.error(msg);
        }
      });
    },
    //   开始考试
    startTest() {
      let that = this;
      this.$loading.show();
      startTest(this.testId).then((json) => {
        that.$loading.hide();
        const { code, data, msg } = json;
        if (code === 200) {
          this.testContent = data;
          // 剩余时间
          let time = this.testTime(
            this.testContent.now,
            this.testContent.endTime
          );
          this.seconds = time % 60;
          this.minutes = Math.floor(time / 60);
          this.countDown();
          this.getAnswerHistory();
        } else {
          if (code == 500) {
            this.$router.push("/myStudy?option=3&path=test");
          }
          this.$message.error(msg);
        }
      });
    },
    // 返回上级页面
    goBack() {
      this.$router.go(-1);
    },
    num: function (n) {
      return n < 10 ? "0" + n : "" + n;
    },
    // 倒计时
    countDown() {
      var that = this;
      this.timer = window.setInterval(function () {
        if (that.seconds === 0 && that.minutes !== 0) {
          that.seconds = 59;
          that.minutes -= 1;
        } else if (that.minutes === 0 && that.seconds === 0) {
          that.seconds = 0;
          // 倒计时结束自动交卷
          if (that.testContent.autoSubmit) {
            that.handInpapers();
          }
          window.clearInterval(that.timer);
        } else {
          that.seconds -= 1;
        }
      }, 1000);
    },
    // 单选题
    chooseAnswer(val) {
      this.answerContent = { ...this.answerContent, ...val };
      this.cacheAnswerList = { ...this.cacheAnswerList, ...val };
    },
    // 判断题存储
    storageAnswer(val, item) {
      this.answerContent = { ...this.answerContent, ...val };
      this.cacheAnswerList = { ...this.cacheAnswerList, ...item };
    },
    // 填空题
    updataAnswer(val, id) {
      let answerList = [];
      val.forEach((e) => {
        let value = Object.values(e)[0];
        // if (value !== "") {
          answerList.push(value);
        // }
      });
      let answer = answerList.join("@;@").toString();
      let obj = {};
      let cacheObj = {};
      obj[id] = answer;
      cacheObj[id] = val;
      this.answerContent = { ...this.answerContent, ...obj };
      this.cacheAnswerList = { ...this.cacheAnswerList, ...cacheObj };
    },
    // 简答题
    updataBlankAnswer(val, id) {
      let obj = {};
      obj[id] = val;
      this.answerContent = { ...this.answerContent, ...obj };
      this.cacheAnswerList = { ...this.cacheAnswerList, ...obj };
    },
    // 交卷
    async handInpapers() {
      try {
        await Promise.reject("error");
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
      let now = await this.getCurrentTime();
      if (this.testContent.allowSubmitTime <= now) {
        handInpapers(
          this.answerContent,
          this.testContent.memberTestPaperId
        ).then((json) => {
          const { code, msg } = json;
          if (code === 200) {
            this.$message({
              type: "success",
              message: "提交成功",
            });
            this.$router.replace("/myStudy?option=3&path=test");
          } else {
            this.$message.error(msg);
          }
        });
      } else {
        let msg = this.surplusTime(
          this.testContent.startTime,
          this.testContent.allowSubmitTime
        );
        this.$message.error(`允许交卷时间为考试开始后${msg}分钟,请继续作答`);
      }
    },
    // 滚动吸顶
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      this.isImbibition = scrollTop > 180 ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
    ...mapActions(["getCurrentTime"]),
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.test_paper_content_sty {
  width: 100%;
  text-align: left;
  .test_paper_content_title_sty {
    height: 86px;
    padding-left: 33px;
    box-sizing: border-box;
    position: relative;
    .test_paper_info_sty {
      margin-top: 21px;
      .back_icon_sty {
        i {
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
        }
      }
      & > div {
        &:first-child {
          margin-bottom: 10px;
        }
        &:nth-child(2) {
          color: #666;
          i {
            margin-right: 4px;
            &:not(:first-child) {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .test_paper_main_sty {
    width: 100%;
    .test_paper_main_title_sty {
      width: 100%;
      background: #f6f6f6;
      height: 48px;
      line-height: 48px;
      padding-left: 32px;
      color: #333;
      font-size: 18px;
      span {
        color: #999;
        font-size: 14px;
      }
    }
    .single_content_sty {
      padding: 0 32px 30px 32px;
      box-sizing: border-box;
      background: #fff;
      .single_item_sty {
        margin-top: 30px;
      }
    }
  }
}
.btn_content_sty {
  width: 100%;
  text-align: right;
}
.btn_sty {
  width: 180px;
  height: 44px;
  background: #0d79ff;
  border-radius: 22px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 44px;
  margin-bottom: 30px;
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
}
.imbibition_content_sty {
  position: fixed;
  top: 0;
  width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  height: 66px;
  z-index: 999;
  .left_content_syt {
    margin-top: 10px;
    & > div:last-child {
      margin-top: 5px;
    }
  }
}
.count_down_content_sty {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 33px;
  color: #ff900d;
  & > div {
    display: inline-block;
    background: #ff900d;
    color: #fff;
    width: 35px;
    height: 33px;
    line-height: 33px;
    font-size: 18px;
    text-align: center;
    border-radius: 4px;
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
  span {
    width: 20px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
  }
}
.icon_col_sty {
  color: #0d79ff;
}
</style>